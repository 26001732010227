import * as React from "react"
import Seo from "@components/seo"
import Layout from "@layout"
import Header from "@layout/header/header-one"
import Footer from "@layout/footer/footer-one"
import PageHeader from "@components/pageheader";
import AboutArea from "@containers/index-processing/about-area"
import ContactArea from "@containers/index-infotechno/contact-area"

import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import slide2 from "@assets/images/interiordesign/arabisk.jpg"
import slide1 from "@assets/images/interiordesign/plane of flats.jpg"
import slide3 from "@assets/images/interiordesign/3.jpg"
import slide4 from "@assets/images/interiordesign/4.jpg"
import slide5 from "@assets/images/interiordesign/interior1.jpg"
import slide6 from "@assets/images/interiordesign/interior2.jpg"


const InteriorDesigns = ({location,pageContext}) => {
    const intl = useIntl()
  const serviceTitles = intl.formatMessage({
    id: "interior_designs",
  })
  const content = [
    {
      image: slide1,
    },
    {
      image: slide2,
    },
    {
      image: slide3,
    },
    {
      image: slide4,
    },
    {
      image: slide5,
    },
    {
      image: slide6,
    },
  ]

  const srviceTexts = [
    {
      text: <FormattedMessage id="Preparation_of_floor_plans" />,
    },
    {
      text: <FormattedMessage id="Preparing_plans_for_ceilings_and_lighting" />,
    },
    {
      text: <FormattedMessage id="Prepare_blueprints_for_the_walls" />,
    },
    {
      text: <FormattedMessage id="Preparing_plans_for_brushes_and_accessories" />,
    },
    {
      text: (
        <FormattedMessage id="3d_screenshots" />
      ),
    },
  ]
    return ( 
        <Layout location={location}>
      <Seo title="Services" />
      <Header />
      <main className="site-wrapper-reveal">
      <PageHeader
			pageContext={pageContext}
			location={location}
			title="IT Services"
      pageTitle="services"
		/>
        <AboutArea
          content={content}
          srviceTexts={srviceTexts}
         serviceTitles={serviceTitles}
         studyRequestUrl="/study-request-interiordesign"
         isStudyRequest={true}
         buttonTextRequest="study_request"
         stopPointer="stopPointer"
        />

        <ContactArea />
      </main>
      <Footer />
    </Layout>
     );
}
 
export default InteriorDesigns;
